import React from 'react';
import data from '../../data/pages/services/refining.json';
import {
  Layout,
  Container,
  OrderProcess,
  ContentBlock,
  ServiceFeed,
  CardGroup,
  Card,
} from '../../components';

const Refining = () => {
  const content = data.content;
  return (
    <Layout pageContext={data}>
      <Container addSpacers>
        {/* Refining steps */}
        <CardGroup
          columnCount={2}
          headerProps={{
            title: content.refiningSteps.title,
            text: content.refiningSteps.subtitle,
          }}>
          {content.refiningSteps.steps.map((step, i) => {
            const { title, text } = step;
            return <Card key={i} subtitle={`Step ${i + 1}`} {...{ title, text }} />;
          })}
          <Card />
        </CardGroup>

        {/* Order Processing */}
        <OrderProcess />

        {/* Refining Statement */}
        <ContentBlock
          title={content.statement.title}
          content={content.statement.content}
          cta={content.statement.cta}
          buttonProps={{ styleType: 'flat', showArrow: true }}
        />

        {/* Service List */}
        <ServiceFeed exclude={['refining']} scrollOnMobile />
      </Container>
    </Layout>
  );
};

export default Refining;
